// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"

Rails.start()
Turbolinks.start()

document.addEventListener("DOMContentLoaded", function() {
  const brandbar = document.getElementById("brandbar");
  let offset = brandbar.offsetHeight;

  document.addEventListener("scroll", function() {
    if (window.scrollY >= offset) {
      if (!brandbar.classList.contains("scrolled")) {
        brandbar.classList.add("scrolled");
      }
    } else {
      if (brandbar.classList.contains("scrolled")) {
        brandbar.classList.remove("scrolled");
      }
    }
  });





  const buttonHideFlash = document.getElementById("button-hide-flash");
  const flash = document.getElementById("flash");

  if (buttonHideFlash) {
    buttonHideFlash.addEventListener("click", function() {
      flash.remove();
    });
  }





  const submitSend = document.getElementById("submit-send");

  const mailMessageName = document.getElementById("mail_message_name");
  const mailMessageEmail = document.getElementById("mail_message_email");
  const mailMessageSubject = document.getElementById("mail_message_subject");
  const mailMessageMessage = document.getElementById("mail_message_message");

  submitSend.addEventListener("click", function() {
    if (!mailMessageName.value && !mailMessageName.classList.contains("input-error")) {
      mailMessageName.classList.add("input-error");
    } else if (mailMessageName.value && mailMessageName.classList.contains("input-error")) {
      mailMessageName.classList.remove("input-error");
    }

    if (!mailMessageEmail.value && !mailMessageEmail.classList.contains("input-error")) {
      mailMessageEmail.classList.add("input-error");
    } else if (mailMessageEmail.value && mailMessageEmail.classList.contains("input-error")) {
      mailMessageEmail.classList.remove("input-error");
    }

    if (!mailMessageSubject.value && !mailMessageSubject.classList.contains("input-error")) {
      mailMessageSubject.classList.add("input-error");
    } else if (mailMessageSubject.value && mailMessageSubject.classList.contains("input-error")) {
      mailMessageSubject.classList.remove("input-error");
    }

    if (!mailMessageMessage.value && !mailMessageMessage.classList.contains("input-error")) {
      mailMessageMessage.classList.add("input-error");
    } else if (mailMessageMessage.value && mailMessageMessage.classList.contains("input-error")) {
      mailMessageMessage.classList.remove("input-error");
    }
  });





  const buttonAcceptCookies = document.getElementById("button-accept-cookies");
  const buttonHideCookies = document.getElementById("button-hide-cookies");
  const cookies = document.getElementById("cookies");

  if (buttonAcceptCookies) {
    buttonAcceptCookies.addEventListener("click", function() {
      let date = new Date();
      let time = date.getTime();

      time += 1000 * 60 * 60 * 24 * 182;
      date.setTime(time);

      document.cookie = 'cookies_accepted=true;expires=' + date.toUTCString() + ';path=/;';

      cookies.remove();
    });
  }

  if (buttonHideCookies) {
    buttonHideCookies.addEventListener("click", function() {
      cookies.remove();
    });
  }
});
